import React from "react";
import { Avatar, Tag } from "tarmac-v3";

const TagHrms = ({
  label, backgroundColor, borderColor, avatar, tagSource, onIconClick, icon,
}) => (
  <Tag
    label={label}
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    variant="filled"
    avatar={avatar && <Avatar src={avatar} />}
    onIconClick={onIconClick}
    icon={icon}
    size="small"
    sx={{
      height: "24px",
      paddingLeft: tagSource === "HRMS" ? "4px" : "none",
      "& .MuiChip-label": {
        paddingLeft: avatar && 1,
      },
    }}
  />

);

export default TagHrms;
