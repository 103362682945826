export const hasExtendedDeliveryDelay = (country, keyboard) => {
  const keyboardByCountry = [
    { label: "France", value: "FR - Français - AZERTY" },
    { label: "Spain", value: "ES - Espagnol - QWERTY" },
    { label: "United Kingdom", value: "UK - Anglais - QWERTY" },
    { label: "Germany", value: "DE - Allemand - QWERTZ" },
  ];

  const localKeyboard = keyboardByCountry.find((kb) => kb.label === country);

  if (localKeyboard) {
    return localKeyboard.value !== keyboard;
  }

  return true;
};

export const devicesOSTypesMatching = {
  macos: "MacOS",
  windows: "Windows",
  linux: "Linux",
  ipados: "iPadOS",
  android: "Android",
  ios: "iOS",
};

export const formatSize = (size) => {
  if (size < 1024) {
    return `${size} Octets`;
  }

  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} Ko`;
  }

  if (size < 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} Mo`;
  }

  return `${(size / (1024 * 1024 * 1024)).toFixed(2)} Go`;
};

export const getDeviceStatusLabelAndColor = (device, country) => {
  if (device) {
    if (device.status === "PENDING") {
      return { label: "En cours de validation", color: "purple", color_not_badge: "pending" };
    }

    if (["READY_FOR_PURCHASE", "PURCHASED"].includes(device.status)) {
      return { label: "En préparation", color: "purple", color_not_badge: "pending" };
    }

    if (country === "france" && device.status === "DELIVERED") {
      return { label: "Livré", color: "green", color_not_badge: "success" };
    }

    if (["SHIPPED", "DELIVERED", "BDL_SENT"].includes(device.status)) {
      return { label: "En livraison", color: "purple", color_not_badge: "pending" };
    }

    if (["SPARE", "UNASSIGNED"].includes(device.usage_status)) {
      return { label: "Disponible", color: "green", color_not_badge: "success" };
    }

    if (device.usage_status === "RUNNING") {
      return { label: "En service", color: "green", color_not_badge: "success" };
    }

    if (device.usage_status === "SAV") {
      return { label: "En maintenance", color: "orange", color_not_badge: "warning" };
    }

    if (device.usage_status === "OUT_OF_SERVICE") {
      return { label: "Hors service", color: "red", color_not_badge: "error" };
    }
  }

  return { label: "Archivé", color: "grey", color_not_badge: "disabled" };
};

export const isMenuOptionVisible = (device, option) => {
  if (option === "MOVE_TO_SPARE") {
    return ["RUNNING", "UNASSIGNED", "OUT_OF_SERVICE"].includes(device.usage_status);
  }

  if (option === "MOVE_TO_SAV") {
    return ["RUNNING", "UNASSIGNED", "SPARE"].includes(device.usage_status) && device.status === "RECEIVED";
  }

  if (option === "MOVE_TO_OUT_OF_SERVICE") {
    return ["RUNNING", "UNASSIGNED", "SPARE", "SAV"].includes(device.usage_status) && device.source === "EXTERNAL";
  }

  if (option === "MOVE_TO_RUNNING") {
    return ["SAV", "OUT_OF_SERVICE"].includes(device.usage_status);
  }

  return false;
};

export const getMdmInstallationBaseUrl = (osType) => {
  switch (osType) {
    case "linux":
      return "/mdm-linux-installation";
    case "windows":
      return "/mdm-windows-installation";
    default:
      return "/mdm-installation";
  }
};

export const getKeyboardLabel = (keyboardLayout) => {
  switch (keyboardLayout) {
    case "FR - Français - AZERTY":
      return " 🇫🇷 AZERTY ";
    case "ES - Espagnol - QWERTY":
      return " 🇪🇸 QWERTY ";
    case "UK - Anglais - QWERTY":
      return " 🇬🇧 QWERTY ";
    case "ENG - Anglais international - QWERTY":
      return " 🌐 QWERTY ";
    case "DE - Allemand - QWERTZ":
      return " 🇩🇪 QWERTZ ";
    case "IT - Italien - QWERTY":
      return " 🇮🇹 QWERTY ";
    case "PT - Portugais - QWERTY":
      return " 🇵🇹 QWERTY ";
    case "other":
    default:
      return "";
  }
};

export const applyCategoryFilters = (devices, filters) => {
  const accessoryCategories = ["SCREEN", "HEADPHONES", "SPEAKERS", "KEYBOARD", "MOUSE", "ADAPTER", "CHARGER", "CASE", "OTHER"];
  const furnitureCategories = ["PHONEBOOTH", "CHAIR", "DESK", "ACOUSTIC_CHAIR"];
  const categoriesToFilter = [...filters.category];

  if (filters.category.includes("ACCESSORY")) {
    const accessoryIndex = categoriesToFilter.indexOf("ACCESSORY");

    categoriesToFilter.splice(accessoryIndex, 1, ...accessoryCategories);
  }

  if (filters.category.includes("FURNITURE")) {
    const furnitureIndex = categoriesToFilter.indexOf("FURNITURE");

    categoriesToFilter.splice(furnitureIndex, 1, ...furnitureCategories);
  }

  if (categoriesToFilter.length === 0) {
    categoriesToFilter.push("COMPUTER", "TABLET", "SMARTPHONE", ...accessoryCategories, ...furnitureCategories);
  }

  return devices.filter((device) => categoriesToFilter.includes(device.category));
};

export const applyStatusFilters = (devices, filters) => devices.filter((device) => {
  if (filters.status.length === 0) {
    return true;
  }

  if (filters.status.includes("PURCHASING") && ["WAITING", "PURCHASED"].includes(device.status)) {
    return true;
  }

  if (!filters.status.includes("PURCHASING") && ["WAITING", "PURCHASED"].includes(device.status)) {
    return false;
  }

  if (filters.status.includes("SHIPPED") && ["SHIPPED", "DELIVERED", "BDL_SENT"].includes(device.status)) {
    return true;
  }

  if (!filters.status.includes("SHIPPED") && ["SHIPPED", "DELIVERED", "BDL_SENT"].includes(device.status)) {
    return false;
  }

  if (filters.status.includes("AVAILABLE") && ["SPARE", "UNASSIGNED"].includes(device.usage_status)) {
    return true;
  }

  return filters.status.includes(device.usage_status);
});

export const applySerialNumberFilters = (devices, filters) => devices.filter((device) => {
  if (filters.serialNumber.length === 0) {
    return true;
  }

  if (filters.serialNumber.includes("NONE") && !device.serial_number) {
    return true;
  }

  if (filters.serialNumber.includes(device.serial_number)) {
    return true;
  }

  return false;
});

export const applyTagsFilters = (devices, filters) => devices.filter((device) => {
  if (filters.tags.length === 0) {
    return true;
  }

  if (filters.tags.includes("NONE") && device.tags.length === 0) {
    return true;
  }

  const deviceTagIds = device.tags.map((tag) => tag.id);

  if (filters.tags.some((tagId) => deviceTagIds.includes(tagId))) {
    return true;
  }

  return false;
});

export const applyMdmFilters = (devices, filters) => devices.filter((device) => {
  const errorInstallation = device.mails?.filter((mail) => mail.campaign_id === "mdm_enrollment_retry").length > 0;
  const isDownloaded = device.device_mdm && device.device_mdm.status === "DOWNLOADED";

  const isBlocked = device.device_mdm && device.device_mdm.is_locked;
  const isLockRequested = device.device_mdm && device.device_mdm.lock_date && !isBlocked;

  if (filters.mdm.length === 0) {
    return true;
  }

  if (filters.mdm.includes("NONE") && !device.device_mdm) {
    return true;
  }

  if (filters.mdm.includes("TO_INSTALL") && device.device_mdm && ["SUBSCRIBED"].includes(device.device_mdm.status)) {
    return true;
  }

  if (filters.mdm.includes("INSTALLING") && device.device_mdm && ["IDENTIFIED", "DOWNLOADED", "ENROLLMENT_IN_PROGRESS"].includes(device.device_mdm.status)) {
    return true;
  }

  if (filters.mdm.includes("INSTALLED") && device.device_mdm && device.device_mdm.status === "ENROLLED") {
    return true;
  }

  if (filters.mdm.includes("ERROR_INSTALL") && errorInstallation && isDownloaded) {
    return true;
  }

  if (filters.mdm.includes("BLOCKING") && isLockRequested) {
    return true;
  }

  if (filters.mdm.includes("BLOCKED") && isBlocked) {
    return true;
  }

  return false;
});

export const applyAssignationFilters = (devices, filters) => devices.filter((device) => {
  if (filters.assignation.length === 0) {
    return true;
  }

  if (filters.assignation.includes("SPARE") && device.usage_status === "SPARE") {
    return true;
  }

  if (filters.assignation.includes("UNASSIGNED") && (device.usage_status === "UNASSIGNED" || (!device.user_id && !["SPARE", "ARCHIVED"].includes(device.usage_status)))) {
    return true;
  }

  if (filters.assignation.includes("ASSIGNED") && device.user_id) {
    return true;
  }

  if (filters.assignation.includes(device.user_id)) {
    return true;
  }

  return false;
});

export const applySourceFilters = (devices, filters) => devices.filter((device) => {
  if (filters.source.length === 0) {
    return true;
  }

  if (filters.source.includes("FLEET") && device.source === "FLEET") {
    return true;
  }

  if (filters.source.includes("EX-FLEET") && device.source === "EX-FLEET") {
    return true;
  }

  if (filters.source.includes("EXTERNAL") && device.source === "EXTERNAL") {
    return true;
  }

  return false;
});

export const applyOrderFilters = (devices, filters) => devices.filter((device) => {
  if (filters.order.length === 0) {
    return true;
  }

  if (filters.order.includes("NONE") && (!device.order || !device.order.order_number)) {
    return true;
  }

  if (filters.order.includes(device.order && device.order.order_number)) {
    return true;
  }

  return false;
});

export const applyInternalIdFilters = (devices, filters) => devices.filter((device) => {
  if (filters.internalId.length === 0) {
    return true;
  }

  if (filters.internalId.includes("NONE") && !device.internal_id) {
    return true;
  }

  if (filters.internalId.includes(device.internal_id)) {
    return true;
  }

  return false;
});

export const applyOsConfigFilters = (devices, filters) => devices.filter((device) => {
  if (filters.osConfig.length === 0) {
    return true;
  }

  if (filters.osConfig.includes("NONE") && !device.os_config) {
    return true;
  }

  if (filters.osConfig.includes("WINDOWS") && device.os_platform === "windows") {
    return true;
  }

  if (filters.osConfig.includes("MACOS") && device.os_platform === "macos") {
    return true;
  }

  if (filters.osConfig.includes("LINUX") && device.os_platform === "linux") {
    return true;
  }

  return false;
});

export const applyStorageFilters = (devices, filters) => devices.filter((device) => {
  if (filters.storage.length === 0) {
    return true;
  }

  if (filters.storage.includes("NONE") && !device.storage) {
    return true;
  }

  if (device.storage) {
    if (filters.storage.includes(device.storage)) {
      return true;
    }
  }

  return false;
});

export const applyRamFilters = (devices, filters) => devices.filter((device) => {
  if (filters.ram.length === 0) {
    return true;
  }

  if (filters.ram.includes("NONE") && !device.ram) {
    return true;
  }

  if (device.ram) {
    if (filters.ram.includes(device.ram)) {
      return true;
    }
  }

  return false;
});

export const applyScreenSizeFilters = (devices, filters) => devices.filter((device) => {
  if (filters.screenSize.length === 0) {
    return true;
  }

  if (filters.screenSize.includes("NONE") && !device.screen_size) {
    return true;
  }

  if (device.screen_size) {
    if (filters.screenSize.includes(device.screen_size)) {
      return true;
    }
  }

  return false;
});

export const applyBrandFilters = (devices, filters) => devices.filter((device) => {
  if (filters.brand.length === 0) {
    return true;
  }

  if (filters.brand.includes("NONE") && !device.brand) {
    return true;
  }

  if (device.brand) {
    if (filters.brand.includes(device.brand)) {
      return true;
    }
  }

  return false;
});

export const applyGraphicFilters = (devices, filters) => devices.filter((device) => {
  if (filters.graphic.length === 0) {
    return true;
  }

  if (filters.graphic.includes("NONE") && !device.graphic) {
    return true;
  }

  if (device.graphic) {
    if (filters.graphic.includes(device.graphic)) {
      return true;
    }
  }

  return false;
});

export const applyKeyboardFilters = (devices, filters) => devices.filter((device) => {
  if (filters.keyboard.length === 0) {
    return true;
  }

  if (filters.keyboard.includes("NONE") && !device.keyboard_layout) {
    return true;
  }

  if (device.keyboard_layout) {
    if (filters.keyboard.includes(device.keyboard_layout)) {
      return true;
    }
  }

  return false;
});

export const applyMdmLocalnameFilters = (devices, filters) => devices.filter((device) => {
  if (filters.mdm_localname.length === 0) {
    return true;
  }

  if (filters.mdm_localname.includes("NONE") && !device?.device_mdm?.localName) {
    return true;
  }

  if (device?.device_mdm?.localName) {
    if (filters.mdm_localname.includes(device?.device_mdm?.localName)) {
      return true;
    }
  }

  return false;
});
