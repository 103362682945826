import {
  useEffect, useMemo, useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useRenewalFunnel } from "src/contexts/RenewalFunnelContext";
import { useUserContext } from "src/contexts/UserContext";

const leasingCheckout = [
  {
    name: "COMPANY_DETAILS",
    slug: "/leasing-checkout/company-details",
    regex: "^/leasing-checkout/company-details$",
    label: "Entreprise",
    companyInfoCompleted: false,
  },
  {
    name: "NOT_ELIGIBLE",
    slug: "/leasing-checkout/not-eligible",
    regex: "^/leasing-checkout/not-eligible$",
    label: "Entreprise",
    companyInfoCompleted: false,
  },
  {
    name: "SHIPPING",
    slug: "/leasing-checkout/shipping",
    regex: "^/leasing-checkout/shipping$",
    label: "Livraison",
    companyInfoCompleted: true,
  },
  {
    name: "ORDER_VALIDATION",
    slug: "/leasing-checkout/validation",
    regex: "^/leasing-checkout/validation$",
    label: "Finalisation",
    companyInfoCompleted: true,
  },
  {
    name: "ATTRIBUTION",
    slug: "/leasing-checkout/attribution",
    regex: "^/leasing-checkout/attribution$",
    label: "Finalisation",
    companyInfoCompleted: true,
  },
];

const newEmployeeSteps = [
  {
    name: "INFORMATION",
    slug: "/new-employee/informations",
    regex: "^/new-employee/informations$",
    label: "Informations",
  },
  {
    name: "DOCUMENTS",
    slug: "/new-employee/documents",
    regex: "^/new-employee/documents$",
    label: "Documents",
  },
  {
    name: "ATTRIBUTION",
    slug: "/new-employee/assignation",
    regex: "^/new-employee/assignation$",
    label: "Attribution",
  },
];

const renewalSteps = [
  {
    name: "DECISION",
    slug: "",
    regex: "^/renewal/([0-9]+)$",
    label: "Options de fin de contrat",
    isReorderStep: false,
  },
  {
    name: "RENEWAL_ORDER",
    slug: "/renewal-order",
    regex: "^/renewal/([0-9]+)/renewal-order(/.*)?$",
    label: "Nouveaux équipements",
    isReorderStep: true,
  },
  {
    name: "SHIPPING",
    slug: "/shipping",
    regex: "^/renewal/([0-9]+)/shipping$",
    label: "Livraison",
    isReorderStep: true,
  },
  {
    name: "FINALIZATION",
    slug: "/finalization",
    regex: "^/renewal/([0-9]+)/finalization$",
    label: "Finalisation",
    isReorderStep: false,
  },
];

const newDeviceSteps = [
  {
    name: "TYPE",
    slug: "/new-device/type",
    regex: "^/new-device/type$",
    label: "Type",
  },
  {
    name: "INFO_TECH",
    slug: "/new-device/specifications",
    regex: "^/new-device/specifications$",
    label: "Information",
  },
  {
    name: "INFO_FIN",
    slug: "/new-device/finance",
    regex: "^/new-device/finance$",
    label: "Information",
  },
  {
    name: "DOCUMENTS",
    slug: "/new-device/documents",
    regex: "^/new-device/documents$",
    label: "Documents",
  },
  {
    name: "ATTRIBUTION",
    slug: "/new-device/assignation",
    regex: "^/new-device/assignation$",
    label: "Attribution",
  },
  {
    name: "ORGANIZATION",
    slug: "/new-device/organization",
    regex: "^/new-device/organization$",
    label: "Organisation",
  },
];

const addDevicesChoiceSteps = [
  {
    name: "SINGLE_DEVICE",
    slug: "/add-devices-choice",
    regex: "^/add-devices-choice$",
    label: "Choix du type d'ajout",
  },
];

const signatorySteps = [
  {
    regex: "^/order-signatory/([0-9]{1,5})$",
    label: "Contrat",
  },
  {
    regex: "^/order-signatory/([0-9]{1,5})/delivery_receipt$",
    label: "Bon de réception",
  },
  {
    regex: "^/order-signatory/([0-9]{1,5})/rib$",
    label: "RIB",
  },
];

const deviceReturnSteps = [
  {
    name: "PREPARATION",
    slug: "/return",
    regex: "^/return/([0-9]+)$",
    label: "Préparation",
  },
  {
    name: "INFORMATION",
    slug: "/return/information",
    regex: "^/return/([0-9]+)/information$",
    label: "Retour",
  },
  {
    name: "SUMMARY",
    slug: "/return/summary",
    regex: "^/return/([0-9]+)/summary$",
    label: "Récapitulatif",
  },
];

const devicesBatchImportSteps = [
  {
    name: "IMPORT",
    slug: "/devices-batch-import-csv/import",
    regex: "^/devices-batch-import-csv/import$",
    label: "Import",
  },
  {
    name: "ATTRIBUTION",
    slug: "/devices-batch-import-csv/assignation",
    regex: "^/devices-batch-import-csv/assignation$",
    label: "Attribution",
  },
  {
    name: "CONFIRMATION",
    slug: "/devices-batch-import-csv/confirmation",
    regex: "^/devices-batch-import-csv/confirmation$",
    label: "Confirmation",
  },
];

const employeesBatchImportSteps = [
  {
    name: "IMPORT",
    slug: "/employees-batch-import",
    regex: "^/employees-batch-import$",
    label: "Import",
  },
];

const csvBatchImportSteps = [
  {
    name: "IMPORT",
    slug: "/employees-batch-import-csv/import",
    regex: "^/employees-batch-import-csv/import$",
    label: "Import",
  },
  {
    name: "ATTRIBUTION",
    slug: "/employees-batch-import-csv/attribution",
    regex: "^/employees-batch-import-csv/attribution$",
    label: "Import",
  },
  {
    name: "CONFIRMATION",
    slug: "/employees-batch-import-csv/confirmation",
    regex: "^/employees-batch-import-csv/confirmation$",
    label: "Confirmation",
  },
];

const googleBatchImportSteps = [
  {
    name: "IMPORT",
    slug: "/employees-batch-import-google/import",
    regex: "^/employees-batch-import-google/import$",
    label: "Import",
  },
  {
    name: "CONFIRMATION",
    slug: "/employees-batch-import-google/confirmation",
    regex: "^/employees-batch-import-google/confirmation$",
    label: "Confirmation",
  },
];

const payfitBatchImportSteps = [
  {
    name: "IMPORT",
    slug: "/employees-batch-import-payfit/import",
    regex: "^/employees-batch-import-payfit/import$",
    label: "Connexion à PayFit",
  },
  {
    name: "MATCHING",
    slug: "/employees-batch-import-payfit/matching",
    regex: "^/employees-batch-import-payfit/matching$",
    label: "Synchronisation de vos employés",
  },
  {
    name: "MISMATCHING",
    slug: "/employees-batch-import-payfit/mismatching",
    regex: "^/employees-batch-import-payfit/mismatching$",
    label: "Synchronisation de vos employés",
  },
  {
    name: "SUMMARY",
    slug: "/employees-batch-import-payfit/summary",
    regex: "^/employees-batch-import-payfit/summary$",
    label: "Confirmation",
  },
];

const factorialBatchImportSteps = [
  {
    name: "INIT",
    slug: "/employees-batch-import-factorial",
    regex: "^/employees-batch-import-factorial$",
    label: "Import",
  },
  {
    name: "CONFIRMATION",
    slug: "/employees-batch-import-factorial/confirmation",
    regex: "^/employees-batch-import-factorial/confirmation$",
    label: "Confirmation",
  },
];

const luccaBatchImportSteps = [
  {
    name: "INIT",
    slug: "/employees-batch-import",
    regex: "^/employees-batch-import$",
    label: "Import",
  },
  {
    name: "LOGIN",
    slug: "/employees-batch-import-lucca/login",
    regex: "^/employees-batch-import-lucca/login$",
    label: "Connexion à Lucca",
  },
  {
    name: "CONFIRMATION",
    slug: "/employees-batch-import-lucca/confirmation",
    regex: "^/employees-batch-import-lucca/confirmation$",
    label: "Confirmation",
  },
];

const personioBatchImportSteps = [
  {
    name: "LOGIN",
    slug: "/employees-batch-import-personio/login",
    regex: "^/employees-batch-import-personio/login$",
    label: "Connexion à Personio",
  },
  {
    name: "CONFIRMATION",
    slug: "/employees-batch-import-personio/confirmation",
    regex: "^/employees-batch-import-personio/confirmation$",
    label: "Synchronisation de vos employés",
  },
];

const mdmEnrollmentSteps = [
  {
    name: "COMPANY_DETAILS",
    slug: "/mdm-enrollment/company-details",
    regex: "^/mdm-enrollment/company-details$",
    label: "Entreprise",
    companyInfoCompleted: false,
  },
  {
    name: "ROUTE_CHOICE",
    slug: "/mdm-enrollment/route-choice",
    regex: "^/mdm-enrollment/route-choice$",
    label: "Choix du parcours",
    companyInfoCompleted: true,
  },
  {
    name: "EQUIPMENTS",
    slug: "/mdm-enrollment/equipments",
    regex: "^/mdm-enrollment/equipments$",
    label: "Choix des équipements",
    companyInfoCompleted: true,
  },
  {
    name: "FINALIZATION",
    slug: "/mdm-enrollment/finalization",
    regex: "^/mdm-enrollment/finalization$",
    label: "Finalisation",
    companyInfoCompleted: true,
  },
  {
    name: "SUMMARY",
    slug: "/mdm-enrollment/summary",
    regex: "^/mdm-enrollment/summary$",
    label: "Récapitulatif",
    companyInfoCompleted: true,
  },
];

const mdmInstallationSteps = [
  {
    name: "INFORMATIONS",
    slug: "/mdm-installation/informations",
    regex: "^/mdm-installation/([0-9]+)$",
    label: "Informations",
  },
  {
    name: "OS_SYSTEM",
    slug: "/mdm-installation/os-system",
    regex: "^/mdm-installation/([0-9]+)/os-system$",
    label: "Système d'exploitation",
  },
  {
    name: "SERIAL_NUMBER",
    slug: "/mdm-installation/serial-number",
    regex: "^/mdm-installation/([0-9]+)/serial-number$",
    label: "N° de série",
  },
  {
    name: "FINALIZATION",
    slug: "/mdm-installation/finalization",
    regex: "^/mdm-installation/([0-9]+)/finalization$",
    label: "Installation",
  },
];

const mdmWindowsInstallationSteps = [
  {
    name: "INFORMATIONS",
    slug: "/mdm-windows-installation/informations",
    regex: "^/mdm-windows-installation/([0-9]+)$",
    label: "Informations",
  },
  {
    name: "OS_SYSTEM",
    slug: "/mdm-windows-installation/os-system",
    regex: "^/mdm-windows-installation/([0-9]+)/os-system$",
    label: "Système d'exploitation",
  },
  {
    name: "DOWNLOAD",
    slug: "/mdm-windows-installation/download",
    regex: "^/mdm-windows-installation/([0-9]+)/download$",
    label: "Téléchargement",
  },
  {
    name: "FINALIZATION",
    slug: "/mdm-windows-installation/finalization",
    regex: "^/mdm-windows-installation/([0-9]+)/finalization$",
    label: "Installation",
  },
];

const mdmLinuxInstallationSteps = [
  {
    name: "INFORMATIONS",
    slug: "/mdm-linux-installation/informations",
    regex: "^/mdm-linux-installation/([0-9]+)$",
    label: "Informations",
  },
  {
    name: "OS_SYSTEM",
    slug: "/mdm-linux-installation/os-system",
    regex: "^/mdm-linux-installation/([0-9]+)/os-system$",
    label: "Système d'exploitation",
  },
  {
    name: "PACKAGE",
    slug: "/mdm-linux-installation",
    regex: "^/mdm-linux-installation/([0-9]+)/package$",
    label: "Téléchargement",
  },
  {
    name: "INSTALLATION",
    slug: "/mdm-linux-installation/installation",
    regex: "^/mdm-linux-installation/([0-9]+)/installation$",
    label: "Installation",
  },
];

const mdmUnenrollmentSteps = [
  {
    name: "DEACTIVATION",
    slug: "/mdm-unenrollment/deactivation",
    regex: "^/mdm-unenrollment/deactivation$",
    label: "Désactivation",
  },
  {
    name: "CANCELLATION",
    slug: "/mdm-unenrollment/cancellation",
    regex: "^/mdm-unenrollment/cancellation$",
    label: "Désabonnement",
  },
];

const mdmAppsSteps = [
  {
    name: "DEPLOYMENT",
    slug: "/mdm-apps/deployment",
    regex: "^/mdm-apps/deployment$",
    label: "Confirmation du déploiement",
  },
  {
    name: "SUMMARY",
    slug: "/mdm-apps/summary",
    regex: "^/mdm-apps/summary$",
    label: "Récapitulatif",
  },
];

const createOrImportDevices = [
  {
    name: "TYPE_IMPORT",
    slug: "/create-or-import-devices/choice",
    regex: "^/create-or-import-devices/choice$",
    label: "Choix du type d'ajout",
  },
];

const funnelSteps = {
  "leasing-checkout": leasingCheckout,
  "new-employee": newEmployeeSteps,
  "new-device": newDeviceSteps,
  "add-devices-choice": addDevicesChoiceSteps,
  "devices-batch-import-csv": devicesBatchImportSteps,
  "employees-batch-import": employeesBatchImportSteps,
  "employees-batch-import-csv": csvBatchImportSteps,
  "employees-batch-import-google": googleBatchImportSteps,
  "employees-batch-import-payfit": payfitBatchImportSteps,
  "employees-batch-import-personio": personioBatchImportSteps,
  "employees-batch-import-factorial": factorialBatchImportSteps,
  "employees-batch-import-lucca": luccaBatchImportSteps,
  "order-signatory": signatorySteps,
  renewal: renewalSteps,
  return: deviceReturnSteps,
  "mdm-enrollment": mdmEnrollmentSteps,
  "mdm-unenrollment": mdmUnenrollmentSteps,
  "mdm-installation": mdmInstallationSteps,
  "mdm-windows-installation": mdmWindowsInstallationSteps,
  "mdm-linux-installation": mdmLinuxInstallationSteps,
  "mdm-apps": mdmAppsSteps,
  "create-or-import-devices": createOrImportDevices,
};
const funnelsList = [
  "/add-devices-choice",
  "/new-device",
  "/new-employee",
  "/employees-batch-import",
  "/employees-batch-import-csv",
  "/employees-batch-import-google",
  "/employees-batch-import-payfit",
  "/employees-batch-import-personio",
  "/employees-batch-import-factorial",
  "/employees-batch-import-lucca",
  "/devices-batch-import-csv",
  "/leasing-checkout",
  "/new-ticket",
  "/renewal",
  "/return",
  "/mdm-enrollment",
  "/mdm-installation",
  "/mdm-linux-installation",
  "/mdm-windows-installation",
  "/mdm-unenrollment",
  "/mdm-apps",
  "/create-or-import-device",
];

const companyHasActivatedMdm = (userCompany) => userCompany.company_mdm && userCompany.company_mdm.status === "ACTIVE";

const isMdmOrFirstOrderCompleted = (user) => {
  if (companyHasActivatedMdm(user.company) || !user.isFirstOrder()) {
    return true;
  }

  return false;
};

const useFunnelNavigation = () => {
  const { pathname } = useLocation();
  const [, funnel] = pathname.split("/");
  const { user: currentUser } = useUserContext();
  const { deviceRenewalInfos } = useRenewalFunnel();
  const steps = useMemo(() => {
    if (["leasing-checkout", "mdm-enrollment"].includes(funnel) && currentUser.companyInfoCompleted() && isMdmOrFirstOrderCompleted(currentUser)) {
      return funnelSteps[funnel].filter((s) => s.companyInfoCompleted);
    }

    if (funnel === "mdm-unenrollment") {
      return funnelSteps[funnel].filter((s) => {
        const slugStep = s.name.toLowerCase();

        const pathnameStep = pathname.split("/")[2];

        return slugStep === pathnameStep;
      });
    }

    if (funnel === "renewal") {
      const hasDevicesToRenew = deviceRenewalInfos?.some((device) => device.renewal_type === "RENEW");

      // Filter funnel steps based on the need for returning and renewing devices
      return funnelSteps[funnel].filter((step) => !step.isReorderStep || hasDevicesToRenew);
    }

    return funnelSteps[funnel];
  }, [currentUser, funnel, deviceRenewalInfos, pathname]);

  const [stepLabels, setStepLabels] = useState([]);
  const [currentStep, setCurrentStep] = useState(null);
  const [nextStepSlug, setNextStepSlug] = useState("");

  useEffect(() => {
    if (steps) {
      const newCurrentStepIndex = steps.findIndex((s) => pathname.match(new RegExp(s.regex)));

      const newCurrentStep = steps[newCurrentStepIndex];

      if (newCurrentStepIndex < steps.length - 1) {
        setNextStepSlug(steps[newCurrentStepIndex + 1].slug);
      }
      const uniqueSteps = [...new Set(steps.map((s) => s.label))];
      const newStepLabels = uniqueSteps.map((label) => ({ label }));

      setStepLabels(newStepLabels);
      setCurrentStep(newCurrentStep);
    }
  }, [pathname, steps, funnel]);

  return {
    currentStep,
    steps,
    stepLabels,
    nextStepSlug,
    funnel,
    funnelsList,
  };
};

export default useFunnelNavigation;
